import { differenceInDays } from "date-fns";
import {
  Details,
  Hotel,
  HotelBrand,
  Product,
  Reservation,
} from "../../@types/IDatalayers";
import { getLoggedStatus, getLoggedUserInfo } from "../helpers";
import { sha256 } from "./sha256";

export const editResConfirmationGTMDataLayer = async (
  res: Reservation[] | null,
  hotel: Hotel,
  hotelBrand: HotelBrand | null,
  details: Details,
  confirmationType?: string
): Promise<void> => {
  const loggedState = getLoggedStatus();
  const { email, profileType } = getLoggedUserInfo();
  let emailHash = "";
  if (email) {
    emailHash = await sha256(email);
  }
  if (res) {
    const resDetail = res[0].details;
    const address = resDetail.hotelAddress;
    window.rlPAGENAME = "reservation-confirmation";
    window.rlPROPERTYCODE = resDetail.hotel.id;
    window.rlPROPERTYNAME = hotel.name;
    window.rlCITY = address.cityName;
    window.rlSTATE = address.region;
    window.rlZIPCODE = address.postalCode;
    window.rlCOUNTRY = address.country;
    window.rlCURRENCYCODE = "USD";

    const numNights = differenceInDays(
      new Date(resDetail.End),
      new Date(resDetail.Start)
    );
    let roomTotal = 0,
      totalTax = 0,
      totalUpgrades = 0;
    localStorage["gtmLastReservationId"] = res[0].crs_reservation_id;

    let adults = 0;
    let children = 0;
    let totalGuests = 0;
    const addOns: { "Add-On": string; "Add-On_Revenue": number }[] = [];

    res.forEach((r, rIndex) => {
      const rDetail = r.details;
      const payment = {
        Subtotal: 0,
        Upgrades: 0,
        Taxes: 0,
        Total: 0,
      };
      rDetail.roomRates.forEach((item) => {
        payment.Subtotal += item.subTotal
          ? parseFloat(item.subTotal)
          : item.Subtotal
          ? parseFloat(item.Subtotal)
          : 0;
        payment.Upgrades += item.upgrades
          ? parseFloat(item.upgrades)
          : item.Upgrades
          ? parseFloat(item.Upgrades)
          : 0;
        payment.Taxes += item.taxes
          ? parseFloat(item.taxes)
          : item.Taxes
          ? parseFloat(item.Taxes)
          : 0;
        payment.Total += item.total
          ? parseFloat(item.total)
          : item.Total
          ? parseFloat(item.Total)
          : 0;
      });

      if (rDetail.services && rDetail.services.length > 0) {
        rDetail.services.forEach((service) => {
          addOns.push({
            "Add-On": service.description,
            "Add-On_Revenue": service.price.total || 0,
          });
        });
      }

      adults = adults + +r.details.count.adult;
      if (r.details.count.child) {
        children = children + +r.details.count.child;
      }
      totalGuests = adults + children;

      window["rlCONFIRMATIONNUMBER_" + rIndex] = r.crs_reservation_id;

      window["rlARRIVAL_" + rIndex] = rDetail.Start;
      window["rlDEPARTURE_" + rIndex] = rDetail.End;
      window["rlRATEPLAN_" + rIndex] = rDetail.ratePlanCode;
      window["rlROOMTYPE_" + rIndex] = rDetail.roomTypeCode;
      window["rlROOMNAME_" + rIndex] =
        rDetail.roomDescription && rDetail.roomDescription.name;

      window["rlNUMNIGHTS_" + rIndex] = numNights;
      window["rlADULTS_" + rIndex] = rDetail.count.adult;
      window["rlCHILDREN_" + rIndex] = rDetail.count.child || 0;
      //Dev Check
      window["rlSELECTEDRATETYPE_" + rIndex] = details?.isonlyRate
        ? "Only Rate Displayed"
        : details?.lowestRate === details?.rateCode
        ? "Lowest Rate"
        : "Not the Lowest Rate";
      window["rlRATECOUNT_" + rIndex] = details.rateCount;
      window["rlROOMRATE_" + rIndex] = payment.Subtotal;
      window["rlADDONVALUE_" + rIndex] = payment.Upgrades;
      window["rlTAX_" + rIndex] = payment.Taxes;
      window["rlTOTALRATEWITHTAXESADDON_" + rIndex] = payment.Total;
      window["rlADR_" + rIndex] = parseFloat(
        payment.Subtotal / numNights
      ).toFixed(2);

      roomTotal += parseFloat(payment.Subtotal);
      totalTax += parseFloat(payment.Taxes);
      totalUpgrades += parseFloat(payment.Upgrades);
    });

    window.rlNUMROOMS = res.length;

    window.rlTOTALNUMNIGHTS = numNights * window.rlNUMROOMS;
    window.rlTOTALROOMCOST = roomTotal;
    window.rlTOTALTAX = totalTax;
    window.rlTOTALADDON = totalUpgrades;
    window.rlTOTALCOSTWITHTAXESADDON = roomTotal + totalTax + totalUpgrades;
    window.rlTOTALROOMADDONS = roomTotal + totalUpgrades;
    window.rlTOTALADR = parseFloat(
      window.rlTOTALROOMCOST / window.rlTOTALNUMNIGHTS
    ).toFixed(2);

    if (process.env.NODE_ENV === "production" && typeof gtag !== "undefined") {
      // gtag('event', 'conversion', { 'send_to': 'AW-1010172688/8DxfCKrX0GkQkIbY4QM',
      //   'value': window.rlTOTALROOMADDONS,
      //   'currency': window.rlCURRENCYCODE,
      //   'transaction_id': window.rlCONFIRMATIONNUMBER_0
      // });
    }

    const products: Product[] = [];

    for (let i = 0; i < window.rlNUMROOMS; i++) {
      const product: any = {};
      product.name = window["rlPROPERTYNAME"];
      product.hotel_code = hotel.crs_code || window["rlPROPERTYCODE"];
      product.id = window["rlCONFIRMATIONNUMBER_" + i];
      product.sku = window["rlCONFIRMATIONNUMBER_" + i];
      product.category = window["rlRATEPLAN_" + i];
      product.brand = hotelBrand?.name;
      product.brand_code = hotelBrand?.code;
      product.price = window["rlROOMRATE_" + i] / window["rlNUMNIGHTS_" + i];
      product.quantity = window["rlNUMNIGHTS_" + i];
      product.selectedratetype = window["rlSELECTEDRATETYPE_" + i];
      product.ratecount = window["rlRATECOUNT_" + i];
      products.push(product);
    }
    window.dataLayer &&
      window.dataLayer.push({
        ecommerce: {
          currencyCode: window.rlCURRENCYCODE,
          purchase: {
            actionField: {
              id: window.rlCONFIRMATIONNUMBER_0,
              affiliation: "Sonesta Hotels",
              revenue: window.rlTOTALROOMADDONS,
              tax: window.rlTOTALTAX,
            },
            products: products,
          },
          checkinDate: details.checkinDate,
          checkoutDate: details.checkoutDate,
          lengthOfStay: details.lengthOfStay,
          adults: adults,
          children: children,
          totalGuests: totalGuests,
          city: window.rlCITY,
          rooms: window.rlNUMROOMS,
          rateName: details.rateName,
          ...(email && { emailHash: emailHash }),
          ...(profileType && { profileType: profileType }),
          reservationType: confirmationType,
          addOns: addOns,
        },
        loggedState: loggedState,
        event: "confirmation",
      });
  }
};
